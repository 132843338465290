<template>
    <div class="image-upload-wrap d-flex flex-column align-items-center">
        <Media size="huge" class="border">
            <Img id="image-result" class="w-100 h-100" :src="fileUrl" :alt="alt" />
        </Media>
        <b  v-if="uploading">Uploading Image....</b>
        <div class="pt-3">
            <FormInput class="upload-image" data-target="image-result" id="change-avatar" type="file" max="1" hidden />
            <label class="btn btn-primary btn-md" for="change-avatar" v-if="!uploading" :disabled="true">Change</label>
        </div>
    </div>
</template>

<script>
import FormInput from '@/components/forms/input/FormInput.vue';
import Media from '@/components/media/Media.vue';
import Img from '@/components/img/Img.vue';
import FileMixings from '@/mixings/FileMixings';
export default {
    name: 'ImageUpload',
    mixings : [ FileMixings], 
    props: {
        id : String,
        category: String,
        src: String,
        alt: String,
        noPhotoUrl : String,
    },
    data(){
        return { 
            uploading : false,
            id2: this.id,
        }
    },      
    components: {
        FormInput,
        Media,
        Img
    },
    watch:{
        id2: function(newv){
            this.$emit('change-file',newv);
        },
        id: function(newv){
            this.id2 = newv;
        },
   },
   computed:{
        fileUrl(){
            if(this.id2 == null || this.id2 == '')
                return this.noPhotoUrl;
            return FileMixings.mountPhotoUrl(this.id2);
        }
   },   
    methods: {
        async uploadImage (selector) {
            let _this = this;
            let elem = document.querySelectorAll(selector);
            elem.forEach(item => {
                item.addEventListener("change", async function(){
                    if(item.files && item.files[0]) {
                        let img = document.getElementById(item.dataset.target);
                        let allowedExtensions  = ["jpg", "JPEG", "JPG", "png" ];
                        let fileExtension  = this.value.split(".").pop();
                      
                        if (!allowedExtensions.includes(fileExtension)) {
                            alert("File type not allowed, Please upload jpg, JPG, JPEG, or png file");
                            img.src = this.noPhotoUrl;
                            return;
                        }
                        _this.uploading = true;

                        var { data } = await FileMixings.getSign(item.files[0].name, item.files[0].type, item.files[0].size, _this.category);
                        await FileMixings.uploadFile(item.files[0], data.signToken);
                        _this.fileName = item.files[0].name;
                        _this.id2 = data.fileId;
                        _this.url = data.s3Url;

                        _this.uploading = false;
                        return;
                    }

                })
            });
        }
    },
    mounted(){
        
        this.uploadImage(".upload-image");
    }
}
</script>
