<!-- eslint-disable no-unused-vars -->
<template>
    <DataTable :value="getData" removableSort :rows="datatable.pageSize" :rowsPerPageOptions="[5, 10, 20, 50]"
        :loading="loading" :class="`p-datatable-sm`" style="font-size:13px" @page="onPage($event)"
        v-model:expandedRows="expandedRows" @sort="onSort($event)"
        :paginator="config.components.paginator == false ? false : (getData.length == 0 && loading == false ? false : true)"
        :lazy="data ? false : true" :totalRecords="datatable.totalRecords" resizableColumns
        :selectionMode="(config.components.selectMode ? config.components.selectMode : '')"
        v-model:selection="selectedProduct" :reorderableColumns="true"
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" columnResizeMode="fit" showGridlines>

        <template #header v-if="config.components.headerBar == false ? false : true">
            <div class="d-flex gap-1">
                <div class="gap-col"
                    :class="config.components.searchByInputClass ? config.components.searchByInputClass : 'w-25'">

                    <div class="form-control-wrap" v-if="config.components.searchByInput">
                        <div class="form-control-icon start">
                            <em class="icon ni ni-search"></em>
                        </div>
                        <input v-model="searchBy" ref="searchBy" class="form-control" type="text"
                            v-on:keyup.enter="enterFilter" v-on:keyup="typeFilter"
                            :placeholder="config.pesquisa_placeholder">
                    </div>


                </div>
                <div class="" v-if="config.components.searchByButton">
                    <div class="btn btn  btn-info " @click="filter"> <em class="icon ni ni-search"></em> Search</div>
                </div>

                <slot name="headerBtn"></slot>
                <div class="mt-4" v-if="config.components.reloadButton">
                    <div @click="filter" class="a-link"> <em class="icon ni ni-reload"></em> Refresh Search</div>
                </div>
            </div>

            <div class="dropdown"
                v-if="selectedProduct.length > 0 && config.components.dropdownMultiple == true ? true : false">
                <button type="button" class="btn btn-sm mt-2 btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false">Action with selected ({{ selectedProduct.length }})</button>
                <div class="dropdown-menu" style="">
                    <div>
                        <span class="dropdown-item " style="cursor:pointer"
                            @click="$emit('open-tabs', selectedProduct);"><em class="icon ni ni-arrow-up-right"></em>
                            Open
                            Tabs</span>
                    </div>
                </div>
            </div>
            <Nav class="nav-tabs mt-2 nav-tabs-s" v-if="config.tabs">
                <NavItem v-if="config.tabs.showAll" @click="filterByTab(config.tabs.field, 'ALL')">
                    <NavLink type="button" active v-nk-tab="'#ALL___'">
                        <span :class="config.tabs.allClass">
                            <em :class="config.tabs.allIcon"></em>
                            {{ config.tabs.allLabel }}</span>
                    </NavLink>
                </NavItem>
                <NavItem v-for="tab in config.tabs.values" :key="tab.label"
                    @click="filterByTab(config.tabs.field, tab.value)">
                    <NavLink type="button" v-nk-tab="'#TAB___'">
                        <span :class="tab.class" :style="tab.style">
                            <em :class="tab.icon"></em>
                            {{ tab.label }}
                        </span>
                    </NavLink>
                </NavItem>
            </Nav>
        </template>
        <Column expander style="width: 5rem" v-if="config.components.expanderRow == true ? true : false" />
        <Column selectionMode="multiple" v-if="config.components.rowMultipleSelection == true ? true : false"
            headerStyle="width: 3rem"></Column>
        <Column class="dt-col" :style="`width: ${coluna.width}!important;`" :filterMenuStyle="{ width: coluna.size }"
            v-for="coluna in config.colunas" :key="coluna.id" :field="coluna.field" :sortable="coluna.sortable"
            :sort-field="coluna.field" :header="coluna.label">

            <template #loading>
                <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                    <Skeleton width="60%" height="1rem" />
                </div>
            </template>

            <template #body="{ data }">
                <slot :name="coluna.key" :data="data"> </slot>
            </template>
        </Column>




        <template #expansion="slotProps">
            <slot name="expansion" :data="slotProps.data"></slot>
        </template>

    </DataTable>
    <h3 class="p-5 text-center mb-5 text-muted" v-if="getData.length == 0 && loading == false">
        <em class="icon ni ni-sad"></em>
        {{ config.placeholderNoResults }}
    </h3>
</template>

<!-- eslint-disable vue/no-unused-components -->
<script>
import Nav from '@/components/uielements/nav/Nav.vue';
import NavItem from '@/components/uielements/nav/NavItem.vue';
import NavLink from '@/components/uielements/nav/NavLink.vue';
import TabContent from "@/components/uielements/tab/TabContent.vue";
import TabPane from "@/components/uielements/tab/TabPane.vue";
import { Block } from '@/components/block/Block.vue';
import Card from '@/components/uielements/card/Card.vue';
import Icon from '@/components/icon/Icon.vue';
import Table from '@/components/utilities/table/Table.vue';
import TableHead from '@/components/utilities/table/TableHead.vue';
import TableBody from '@/components/utilities/table/TableBody.vue';
import OverlineTitle from '@/components/misc/OverlineTitle.vue';

import FormCheck from '@/components/forms/form-check/FormCheck.vue';
import FormCheckInput from '@/components/forms/form-check/FormCheckInput.vue';
import Paginate from "vuejs-paginate-next";
import Messages from "@/mixings/Messages";
import Skeleton from 'primevue/skeleton';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
export default {
    components: {
        Nav,
        Skeleton,
        Column,
        DataTable,
        NavItem,
        NavLink,
        TabContent,
        TabPane,
        Block,
        Icon,
        Table,
        TableHead,
        TableBody,
        Card,
        OverlineTitle,
        Paginate,
        FormCheck,
        FormCheckInput,
    },
    props: {
        data: {
            type: Array,
            default: undefined
        },
        config: {
            type: Object,
            default: null
        }
    },
    watch: {
        data: function (newv) {
            this.data2 = newv;
        },
        selectedProduct: function (newv) {
            if (newv && newv != [] && newv != null && newv != '') {
                this.$emit('row-selected', newv);
                this.eraseSelection();
            }
        }
    },
    // components: {PuSkeleton },
    data() {
        return {
            expandedRows: [],
            selectedProduct: [],
            datatable: {
                totalRecords: 0,
                page: 0,
                pageSize: this.config.rowSize || 50,
            },

            avancado: {

            },
            data2: this.data,
            config2: {},
            selecionados: [],
            loading: false,
            items: [],
            searchBy: this.config.searchBy || '',
            currentPage: 1,
            perPage: 2,
            dataFilter: 'all',
            itens: {
                data: [],
                pagination: {
                    totalPaginas: 0,
                    actualPage: 0,
                    total: 0
                }
            },
        }
    },
    computed: {
        getData() {
            if (this.data2) {
                return this.data2;
            }
            return this.itens.data;
        }
    },
    mounted() {
        this.config2 = this.config;
        this.avancado = this.config.avancado;

        /*
        
                var searchBy = this.$route.query.searchBy;
                var pageSize = this.$route.query.pageSize;
                var page = this.$route.query.page;
                var order = this.$route.query.order;
                var order_by = this.$route.query.order_by;
                var advanced = JSON.parse(this.$route.query.advanced);
         
        
                if (searchBy) {
                    this.config2.searchBy = searchBy;
                    alert(searchBy);
                }
                    
        
                if (pageSize)
                    this.datatable.pageSize = pageSize;
        
                if (page)
                    this.datatable.page = page;
        
                if (order)
                    this.config2.order = order;
        
                if (order_by)
                    this.config2.order_by = order_by;
        
                if (advanced)
                    this.avancado = advanced;
                */
        if (this.config.initiateFilter)
            this.filter();
    },
    methods: {
        filterByTab(field, value) {
            if (value == 'ALL') {
                delete this.config2.avancado.filter[field];
            } else {
                this.config2.avancado.filter[field] = value;
            }
            this.avancado = this.config2.avancado;
            this.filter();

        },
        setFocus() {
            this.$refs.searchBy.focus();
        },
        async setSearchBy(searchBy) {
            this.searchBy = searchBy;
            var _this = this;
            window.setTimeout(function () {
                _this.setFocus();
            }, 100);
            this.filter();
        },
        eraseSelection() {
            this.selectedProduct = [];
        },
        onRowSelect(event) {
            this.$emit('row-click', event.data);
        },
        onPage(datatable) {
            this.datatable.page = datatable.page;//(datatable.page == 0 ? datatable.page + 1 : datatable.page);
            this.datatable.pageSize = datatable.rows;
            this.filter();
        },
        onSort(datable) {
            this.config2.order = datable.sortField;
            this.config2.order_by = (datable.sortOrder == 1 ? 'desc' : 'asc');
            this.filter();
        },
        enterFilter() {
            this.datatable.page = 0;
            this.filter();
        },
        typeFilter() {
            if (this.searchBy.length >= 5) {
                this.datatable.page = 0;
                this.filter();
            }
        },
        async filter() {

            if (this.data) {
                return;
            }
            /*
            this.$router.replace({
                name: this.$route.name,
                query: {
                    ...this.$route.query,
                    pageSize: this.datatable.pageSize,
                    page: this.datatable.page,
                    searchBy: this.searchBy,
                    order: this.config.order,
                    order_by: this.config.order_by,
                    advanced: JSON.stringify(this.avancado)
                }
            })  
            */
            this.loading = true;
            try {
                const { data } = await this.config.classe(this.datatable.pageSize, this.datatable.page, this.searchBy, this.config.order, this.config.order_by, this.avancado);
                this.datatable.totalRecords = data.pagination.total;
                this.itens = data;
                this.loading = false;
                //this.selectedProduct = [];
            } catch (e) {
                Messages.error(`Not able to search...`, e);
                this.loading = false;
            }

        }
    }
}
</script>

<style>
.dt-col {
    border-color: #e2e2e2;
    border-style: solid;
    border-width: 1px !important;
}
</style>
