<template>
    <ul class="nk-quick-nav">
        <slot></slot>
    </ul>
</template>

<script>
export default {
  name: 'QuickNav',
}
</script>
