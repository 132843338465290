<template>
    <Dropdown v-model="value2" :options="options" :editable="true" :filter="true" :showClear="false" :disabled="loading"
        :placeholder="(loading ? 'loading...' : 'Select a Type')" class="w-full w100" style="width:100%">
        <template #option="slotProps" v-if="!loading">
            {{ slotProps.option }}
        </template>

    </Dropdown>
    <p v-if="loading" style=" font-size: 0.8em;color: #979797;">Loading...</p>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import EmployMixing from '@/mixings/EmployMixing';
export default {
    components: {
        Dropdown,
    },
    mixins: [EmployMixing],
    data() {
        return {
            loading: true,
            value2: this.value,
            options: []
        }
    },
    watch: {
        value2: function (newv) {
            this.$emit('change-brand', newv);
        },
        value: function (vnew) {
            this.value2 = vnew;
        },
    },
    props: {
        value: {
            type: String,
            default() {
                return ''
            }
        },
    },
    methods: {
        async loadDb() {
            const { data } = await EmployMixing.roles();
            console.dir(data);
            this.options = data;
            this.loading = false;
        }
    },
    mounted() {
        this.loadDb();
    }
}
</script>

<style>
.multiselect-option {
    font-size: 0.875rem !important;
    color: #43476b;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.5625rem 1.125rem;
}

.multiselect-wrapper {
    font-size: 0.875rem !important;
    color: #43476b;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.5625rem 1.125rem;
}
</style>