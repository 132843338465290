<template>
    <ModalContainer animation="fade" style="" :id="inputId" aria-labelledby="exampleModalLgLabel" tabindex="-1">
        <ModalDialog size="lg">
            <ModalContent>
                <ModalHeader>
                    <h5 class="modal-title" id="exampleModalLgLabel" v-if="!myProfile">
                        <em class="icon ni ni-user-add-fill  "></em> View User
                    </h5>
                    <h5 class="modal-title" id="exampleModalLgLabel" v-if="myProfile">
                        <em class="icon ni ni-user-add-fill  "></em> Edit my Profile
                    </h5>
                    <a dismiss="modal" aria-label="Close"></a>
                </ModalHeader>
                <ModalBody v-if="loading == true">
                    Loading User...
                </ModalBody>
                <ModalBody v-if="loading == false">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">

                                <div class="form-group col-md-5">
                                    <label class="form-label">Login <span class="text-danger">*</span></label>
                                    <div class="form-control-wrap">
                                        <input class="form-control" type="text" v-model="dto_user.login" disabled>
                                    </div>
                                </div>
                                <div class="form-group col-md-7">
                                    <label class="form-label">Full Name <span class="text-danger">*</span></label>
                                    <div class="form-control-wrap">
                                        <input class="form-control" type="text" v-model="dto_user.name"
                                            placeholder="Jhon Doe" max="20" maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group col-md-8">
                                    <label class="form-label">E-mail <span class="text-danger">*</span></label>
                                    <div class="form-control-wrap">
                                        <input class="form-control" type="text" v-model="dto_user.email" max="200" maxlength="200">
                                    </div>
                                </div>
                                <div class="form-group col-md-8">
                                    <label class="form-label">Password <span class="text-danger">*</span></label>
                                    <div class="form-control-wrap">
                                        <input class="form-control" type="password" v-model="dto_user.password"
                                            placeholder="Jhon Doe" max="20" maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group col-md-4" v-if="myProfile == false">
                                    <label class="form-label">Role <span class="text-danger">*</span></label>
                                    <div class="form-control-wrap">
                                        <select class="form-select" v-model="dto_user.role">
                                            <option value="SUPERVISOR">SUPERVISOR</option>
                                            <option value="SECRETARY">SECRETARY</option>
                                            <option value="AUDITOR">AUDITOR</option>
                                            <option value="OFFICE">OFFICE</option>
                                            <option value="ADMIN">ADMIN</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Profile Picture</label>
                            <ImageUpload category="thumbnail" @change-file="dto_user.photoId = $event"
                                :id="dto_user.photoId" :noPhotoUrl="noPhotoUrl"></ImageUpload>
                        </div>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <div type="button" class="btn btn-outline-dark" :disabled="isDisabled" dismiss="modal"
                        @click="modal.hide()">
                        <Icon icon="cross"></Icon> Cancel
                    </div>
                    <div type="button" class="btn btn-success" :disabled="isDisabled" @click="update">
                        <em class="icon ni ni-save"></em> Save
                    </div>
                </ModalFooter>
            </ModalContent>
        </ModalDialog>
    </ModalContainer>
</template>
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-unused-components -->
<script>

import ImageUpload from '@/components/forms/form-upload/ImageUpload.vue';
import selectEmployRole from '@/components-smartbase/employ/selectEmployRole.vue'
import selectEmployStatus from '@/components-smartbase/employ/selectEmployStatus.vue'
import Messages from '@/mixings/Messages';
import ColFormLabel from '@/components/forms/form-label/ColFormLabel.vue';
import Icon from '@/components/icon/Icon.vue';
import FormInput from '@/components/forms/input/FormInput.vue';
import ModalFooter from '@/components/uielements/modal/ModalFooter.vue';
import ModalContainer from '@/components/uielements/modal/ModalContainer.vue';
import ModalDialog from '@/components/uielements/modal/ModalDialog.vue';
import ModalContent from '@/components/uielements/modal/ModalContent.vue';
import ButtonClose from '@/components/uielements/button-close/ButtonClose.vue';
import ModalBody from '@/components/uielements/modal/ModalBody.vue';
import ModalHeader from '@/components/uielements/modal/ModalHeader.vue';
import Button from '@/components/uielements/button/Button.vue';
import { Modal } from 'bootstrap';
import UtilMixing from "@/mixings/UtilMixing.js";
import FormLabel from '@/components/forms/form-label/FormLabel.vue';
import { useVuelidate } from "@vuelidate/core";
import selectRelationshipContactLabel from '@/components-smartbase/selectRelationshipContactLabel.vue';
import UserMixing from '../../mixings/UserMixing.js';
export default {
    setup() {
        return { v$: useVuelidate() }
    },
    mixins: [UtilMixing],
    props: {
        inputId: {
            type: String,
            default() {
                return UtilMixing.uuid();
            }
        },
    },
    components: {
        ImageUpload,
        selectEmployRole,

        selectEmployStatus,
        ModalBody,
        selectRelationshipContactLabel,
        Icon,
        ColFormLabel,
        FormLabel,
        FormInput,
        Button,
        ModalFooter,
        ButtonClose,
        ModalContainer,
        ModalContent,
        ModalDialog,
        ModalHeader
    },
    data() {
        return {
            myProfile : false,
            loading: true,
            dto_user: {
                name: '',
                photoId: '',
            },
            modal: {}
        }
    },
    computed: {
        permissionsName() {
            return this.$store.state.config.permissionsName;
        }
    },
    methods: {
        async update() {
            try {
                var dialog = Messages.freezeLoading('Updating user...');
                const { data } = await UserMixing.update(this.dto_user._id, this.dto_user);
                Messages.toast_success(`Successfully updated`);

                if(!this.myProfile)
                    this.$emit('user-updated');

                this.modal.hide();
            } catch (e) {
                Messages.error('Not able to update', e);
            } finally {
                Messages.closeFreeze(dialog);
            }

        },
        async showModal(userId, myProfile = false) {
            this.loading = true;
            this.modal = new Modal('#' + this.inputId);
            this.modal.show();
            this.myProfile = myProfile || false;
            const { data } = await UserMixing.get(userId);
            this.loading = false;
            this.dto_user = data;
        },
    }
}
</script>
<style scoped>
.form-label {
    margin-bottom: 0em;
    margin-top: 1em;
}
</style>