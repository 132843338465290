import {  httpCore } from './http.js'

export default {
    mountPhotoUrl(fileId) {
        var baseUrl = (process.env.VUE_APP_ENV == 'local' ? 'http://localhost:3000' : window.location.origin)
        if(fileId == null || fileId == '')
            return `/images/no-photo.png`;
        return `${baseUrl}/api/file/download/${fileId}`;
    },

    mountFileUrl(fileId) {
        var baseUrl = (process.env.VUE_APP_ENV == 'local' ? 'http://localhost:3000' : window.location.origin)
        return `${baseUrl}/api/file/download/${fileId}`;
    },


    async getSign(fileName, fileType, fileSize, fileCategory) {
        return httpCore.post(`/file/tempSign/`, { fileName, fileType, fileSize, fileCategory})
    },

    async uploadFile(file, signedRequest) {
        
        return new Promise ((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', signedRequest);
        
            xhr.setRequestHeader("Content-Encoding", 'base64');


            xhr.onreadystatechange = () => {
                if(xhr.readyState === 4){
                    if(xhr.status === 200){
                        resolve(true);
                    }
                    else{
                        reject('Erro upload to s3');
                    }
                }
            };
            xhr.send(file);
         })
    },

    async upload(file, category) {
        let formData = new FormData();
        formData.append('file', file);
        return httpCore.post(`/file/upload/${category}`,formData, { 
            headers: { 'Content-Type': 'multipart/form-data' } 
        });
    }
}