
<template> 
    <Nav class="nav-tabs" style="height: 100%;" v-if="tabs2.length > 1"> 
        <NavItem v-for="tab in tabs2" :key="tab.key"> 
            <NavLink type="button"  @click="setActive(tab.key)" :active="(activeTab == tab.key)" :v-nk-tab="tab.key" > 
                <template v-if="tab.icon">
                    
                    <em class="icon ni" :class="tab.icon"></em>
                </template>
                <span class="ms-2" v-if="tab.loading"> Loading...</span>
                <span class="ms-2" v-if="!tab.loading"> {{ tab.label }}</span>
                <span class="ms-2 closeBtn"  @click="removeTab(tab)" v-if="tab.canClose">
                    <em class="icon ni ni-cross"></em>
                </span>
            </NavLink>
        </NavItem>
        <span class="btn btn-sm btn-outline  text-danger ms-2" v-if="canCloseAll" @click="closeAll">Close all</span>
    </Nav>
    <slot v-for="tab in tabs2" :key="tab.key" name="tab" :data="{ tab, activeTab }">
        
    </slot>
    


</template>
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-unused-components -->
<script>

import Skeleton from 'primevue/skeleton';

import Vue3TabsChrome from 'vue3-tabs-chrome'
import 'vue3-tabs-chrome/dist/vue3-tabs-chrome.css'
import Nav from '@/components/uielements/nav/Nav.vue';
import NavItem from '@/components/uielements/nav/NavItem.vue';
import NavLink from '@/components/uielements/nav/NavLink.vue';
export default {
    name: 'ProductsPage',
    components: {
        Nav, NavItem, NavLink,
        Vue3TabsChrome,
    },
    watch: {
        activeTab: function (newv, oldv) {
            this.$emit('tab-active', newv);
        },
        selectedTab: function (newv, oldv) {
            this.$emit('tab-active', newv);
        }
    },
    data() {

        return {
            lastDelete :''
        }
    },
    computed: {
        canCloseAll() {
            var list = this.tabs2;
            if (list) {
                return list.filter(function (d) { return d.canClose }).length >= 2;
            }  
            return false;
        },
        activeTab() {
            return this.$store.state.tabs.activeTab;
        },
        tabs2() {
            return this.$store.state.tabs.tabs;
        }
    },
    methods: {
        setActive(key) {
            if (this.lastDelete != key) {
                this.$store.commit('setTab', key);
                this.$emit('tab-clicked', this.activeTab);
            }
        },
        closeAll() {
            this.$store.commit('removeClosableTabs')
            this.$emit('tab-clicked', this.activeTab);
        },
        removeTab(event) {
            //getPrevious
            this.lastDelete = event.key;
            this.$store.commit('closeTab', event.key)
            this.$emit('tab-clicked', this.activeTab);
        }
    },
}
</script>


<style scoped>
    .closeBtn:hover{
        color:red;
    }
    .nav-link.active{
        isolation: isolate;
        background: #fff!important;
        font-weight: 500;
        border-color: #333!important;
    }


    .nav-link {
        height: 100%!important;
        border-radius: 0px!important;
        isolation: isolate;
        background: #c8c8c855!important;

        border-width: 0px 1px 0px 1px!important;
        border-color: #a8a8a8!important;
    }
    .tabs-background-divider{
    
    }
    .tabs-item{
        border-style: solid;
        border-width: 0px;
        border-color: #a8a8a8;
    }
    .tabs-close{
        top:40%!important;
    }
    .tabs-content {
        position: static!important;
    }
    .vue3-tabs-chrome {
     height: 3em!important;
    }

    .vue3-tabs-chrome{
        padding-top: 0px;
    }
</style>