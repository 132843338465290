import Swal from 'sweetalert2/src/sweetalert2.js';
import { toast } from 'vue3-toastify';
import moment from 'moment-timezone';
import {store} from '../state/store'
export default {
    toast_options(){
        return { 
            theme: 'dark',
            autoClose:3000,
            clearOnUrlChange : false,
            position: toast.POSITION.BOTTOM_RIGHT,
        }
    },
    toast_options_loading(){
        return { 
            theme: 'dark',
            autoClose:3000,
            clearOnUrlChange : false,
            position: toast.POSITION.BOTTOM_RIGHT,
        }
    },
    async toast_promise(pro, loading = "Processing your request...", success = "OK, Success", error ="An error happened, try again") {
        var options = this.toast_options();
        options.type = 'warning';
        toast.promise(
            pro,
            {
                pending: loading,
                success: success,
                error: error,
            },
            {
                theme: 'dark',
                position: toast.POSITION.BOTTOM_RIGHT,
            },
        );
    },
    async toast_warning(title) {
        var options = this.toast_options();
        options.type = 'warning';
        await toast(title,   options); // ToastOptions
    },
    async toast_info(title) {
        var options = this.toast_options();
        options.type = 'info';
        await toast(title,   options); // ToastOptions
    },
    async toast_error(title) {
        var options = this.toast_options();
        options.type = 'error';
        await toast(title,   options); // ToastOptions
    },
    async toast_success(title) {
        var options = this.toast_options();
        options.type = 'success';
        await toast(title,   options); // ToastOptions
    },

    closeFreeze(dialog) {
        dialog.close();
    },
    freezeLoading(title, subtitle = `Please Wait...`) {
        var dialog = Swal.fire({
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            title: title,
            html: ` <p class="fs-5">${subtitle}</p>
                    <div class="progress"><div class="progress-bar bg-info progress-bar-striped progress-bar-animated" style="width: 100%;"></div></div>`,
        });
        return dialog; 
    },
    success(title, subtitle = "", timer = 0){
            Swal.fire({
                icon: 'success',
                title: title,
                html : `<p>${subtitle}</p>`,
                timer: timer,
                timerProgressBar: true,
            });
    },
    warning(title, error){
        if (error && error.http_body) {
            var code = '';
            if (error.http_body.type != 'TESTE') {
                code = `<b>Warning Code:</b> ${error.http_body.type}<br>`;
            }
            var time = moment(new Date()).format("DD/MM/yyyy HH:mm");
            Swal.fire({
                icon: 'warning',
                title: title,
                html : `
                        <p class="alert-warning alert"> <em class="icon ni ni-shield-alert-fill  "></em> ${error.http_body.message}</p>
                        <div style="font-size: 12px; text-align: left;" class="text-muted">
                            ${code}
                            <b>Audit Trace:</b> ${error.http_body.trace}<br>
                            <b>Session:</b> ${store.state.session.sessionId}<br>
                            <b>User:</b> ${store.state.session.userName}<br>
                            <b>Date:</b> ${time}<br>
                        </div>`,
                timerProgressBar: true,
            });
        } else {
            Swal.fire({
                icon: 'warning',
                title: title,
                html : error || '',
                timerProgressBar: true,
            });
        }
    },
    error(title, error){
        console.error(`Messages error: `, error);
        

        if (error.fromApi) {
            this.warning(title, error); 
            return;
        }

        if (error && error.http_body) {
            var code = '';
            if (error.http_body.type != 'TESTE') {
                code = `<b>Error Code:</b> ${error.http_body.type}<br>`;
            }
            var time = moment(new Date()).format("DD/MM/yyyy HH:mm");
            Swal.fire({
                icon: 'error',
                title: title,
                html : `
                        <p class="alert-danger alert">${error.http_body.message}</p>
                        <div style="font-size: 12px; text-align: left;" class="text-muted">
                            ${code}
                            <b>Audit Trace:</b> ${error.http_body.trace}<br>
                            <b>Session:</b> ${store.state.session.sessionId}<br>
                            <b>User:</b> ${store.state.session.userName}<br>
                            <b>Date:</b> ${time}<br>
                        </div>`,
                timerProgressBar: true,
            });
        } else {
            Swal.fire({
                icon: 'danger',
                title: title,
                html : error || '',
                timerProgressBar: true,
            });
        }
    },
    dialog(title, text, buttonConfirm, buttonCancel, icon, confirmButton, cancelButton,   action) {
             const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: confirmButton ? confirmButton : 'btn btn-success ms-2',
                    cancelButton:  cancelButton ?  cancelButton : 'btn btn-outline-dark'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title:  title,
                text: text ? text : "You won't be able to revert this!",
                icon: icon ? icon : 'question',
                showCancelButton: true,
                confirmButtonText: buttonConfirm ? buttonConfirm : 'Yes, delete it!',
                cancelButtonText:  buttonCancel ? buttonCancel : 'No, cancel!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    action(true);
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    /*
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your imaginary file is safe :)',
                        'error'
                    )*/
                }
            })   
    }

}