<template>
    <span class="tg-badge tg-badge-info" v-if="status == 'NEW'">
        New
    </span>
    <span class="tg-badge tg-badge-success" v-else-if="status == 'ACTIVE'">
        Active
    </span>
    <span class="tg-badge tg-badge-purple" v-else-if="status == 'ABSENT'">
        Absent
    </span>
    <span class="tg-badge tg-badge-error" v-else-if="status == 'FIRED'">
        Fired
    </span>
    <span class="tg-badge tg-badge-dark" v-else-if="status == 'INACTIVE'">
        Inactive
    </span>
    <span class="" v-else>
        {{ status2 }}
    </span>
</template>
<script>
export default {
    components: {

    },
    data() {
        return {
            id2: this.id,
            status2: this.status
        }
    },
    watch: {
        id: function (nw) {
            this.id2 = nw
        },
        status: function (nw) {
            this.status2 = nw
        }
    },
    props: {
        fs: {
            type: String,
            default: ""
        },
        id: {
            type: String,
            default: null
        },
        status: {
            type: String,
            default: ""
        },
    },
}
</script>
<style></style>
