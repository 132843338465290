<template>    
    <div class="nk-sidebar nk-sidebar-fixed is-theme" :class="miniMenu ? 'is-compact' : ''" id="sidebar" style="background: transparent; /* border: none; */ box-shadow: 0 0 0px rgb(0 0 0 / 0%);">
      <Menu  :miniMenu="miniMenu" @change="miniMenu = $event" />
    </div><!-- .nki-sidebar -->
</template>

<script>
// import components

import Menu from '@/layout/default/menu/Menu.vue';
import 'simplebar/dist/simplebar.min.js';

export default {
    name: 'AppSidebar',
    data() {
        return {
            miniMenu : false,
        }
    },
  components: {
    Menu,
  },
  mounted(){




  }
}
</script>