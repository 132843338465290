import moment from 'moment';
import { httpCore } from './http.js'
export default {
    get(id) {
        return httpCore.get(`/timesheet/${id}`);
    },
    delete(id) {
        return httpCore.delete(`/timesheet/${id}`);
    },
    add(dto) {
        return httpCore.post(`/timesheet/`, dto);
    },
    update(id, dto) {
        return httpCore.put(`/timesheet/${id}`, dto);
    },
    syncTimeSheetEmployee(id,dto) {
        return httpCore.put(`/timesheet/${id}/syncEmployee`, dto);
    },

    canAddPreviousWeek(){
        if(moment().weekday() == 1 && new Date().getUTCHours() < 16){ //16HRS UTC == 12pm NJ
            return true;
            /*
            var today = new Date();
            var currentDayOfWeek = today.getDay(); // 0 (domingo) a 6 (sábado)
            var diff = currentDayOfWeek + 6; // Para voltar uma semana, adicionamos 6 dias
            var mondayOfLastWeek = new Date(today);
            mondayOfLastWeek.setDate(today.getDate() - diff); // Definindo a data para segunda-feira da semana anterior
            return moment(mondayOfLastWeek).format('YYYY-MM-DD');       
            */
        } else {
            return false;
            //return moment().startOf('isoWeek').format('YYYY-MM-DD');
        }
    },

    periodsAvailable(){

        var periods = [];

        var weekNumber = moment().week();
        var start = moment().isoWeekYear(moment().year()).isoWeek(weekNumber).startOf('isoWeek');
        var end = moment().isoWeekYear(moment().year()).isoWeek(weekNumber).endOf('isoWeek');
        periods.push({
                id : 'CURRENT',
                weekNumber,
                name :`Current Week (${weekNumber}) (${start.format('MM/DD/YYYY')} until ${end.format('MM/DD/YYYY')})`,
                start :start,
                end :end,
            });
        if(this.canAddPreviousWeek()){
            var weekNumberPrevious = moment().week()-1;
            var startPrevious = moment().isoWeekYear(moment().year()).isoWeek(weekNumberPrevious).startOf('isoWeek');
            var endPrevious = moment().isoWeekYear(moment().year()).isoWeek(weekNumberPrevious).endOf('isoWeek');
            periods.push({
                    id : 'PREVIOUS',
                    weekNumber : weekNumberPrevious,
                    name :`Previous Week  (${weekNumberPrevious}) (${startPrevious.format('MM/DD/YYYY')} until ${endPrevious.format('MM/DD/YYYY')})`,
                    start :startPrevious,
                    end :endPrevious,
                });
        }

        return periods;
    },


    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/timesheet-filter/",requestParams);
    },
}