<template>
    <router-link to="/" class="logo-link">
        <div class="logo-wrap">
            <img :src="$store.state.config.logoWhite" >
        </div>
    </router-link>
</template>
<script>
export default {
  name: 'LogoLink',
}
</script>
