<template>
    <li class="nav-item">
        <slot></slot>
    </li>
</template>

<script>
    export default {
        name: 'NavItem'
    }
</script>
