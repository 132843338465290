<template>
    <div class="modal-body">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ModalBody'
    }
</script>
