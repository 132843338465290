<template>
    <div class="search-inline">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SearchInline'
}
</script>
