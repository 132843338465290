<template>
    <li>
        <slot></slot>
    </li>
</template>

<script>
export default {
  name: 'QuickNavItem',
}
</script>
