<template>
  <AppRoot sidebarCollapse="lg" navbarCollapse="md">
    <AppMain>
      <Sidebar></Sidebar>
      <AppWrap>
         <Header @openUser="$refs.modalUser.showModal($store.state.session.userId, true)"></Header>
       
          <AppContent :content="content">
              <modalViewUser ref="modalUser" @user-updated="$refs.datatable.filter()"></modalViewUser>
              <slot>  

              
              </slot>
              
          </AppContent>
       
         <Footer></Footer>
      </AppWrap>
     
    </AppMain>
      
  </AppRoot>
  
</template>

<script>
// @ is an alias to /src
import modalViewUser from '@/components-smartbase/user/modalViewUser.vue';
import AppRoot from '@/layout/global/AppRoot.vue'
import AppMain from '@/layout/global/AppMain.vue'
import AppWrap from '@/layout/global/AppWrap.vue'
import AppContent from '@/layout/global/AppContent.vue'
import Sidebar from '@/layout/default/sidebar/Sidebar.vue'
import Header from '@/layout/default/header/Header.vue'
import Footer from '@/layout/default/footer/Footer.vue'

export default {
  name: 'DefaultLayout',
  components: {
  modalViewUser,
    AppRoot,
    AppMain,
    AppWrap,
    AppContent,
    Sidebar,
    Header,
    Footer
  },
  props:{
    content:{
        type:String,
    }
  }
}
</script>