import { httpCore } from './http.js'
export default {
    sendEmail(dto_email, metadata) {
        const dto = {
            metadata,
            isSystem: false,
            type: 'EMAIL',
            content : dto_email
        };
        return httpCore.post(`/notification`, dto);
    },

    unreadCount() {
        return httpCore.get(`/notification/unreadCount`);
    },
    unread() {
        return httpCore.get(`/notification/unread`);
    },
    markRead(id) {
        return httpCore.post(`/notification/${id}/read`);
    },
    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/notification-filter/",requestParams);
    },
}