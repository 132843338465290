<template>
    <li class="nk-nav-item">
      <slot></slot>
    </li>
</template>

<script>

export default {
  name: 'NavList'
}
</script>
