<template>
    <DropdownMenu size="md">
        <DropdownContent class="dropdown-content-x-lg py-3 border-bottom border-light">
            <MediaGroup>
                <Media size="xl" middle shape="circle">
                    <img :src='`${$store.state.config.apiUrl}/user/${$store.state.session.userId}/avatar`' />
                </Media>
                <MediaText>
                    <div class="lead-text">{{ $store.state.session.userName }}</div>
                    <span class="sub-text"><b>Id</b>: {{ $store.state.session.userId }}</span>
                    <span class="sub-text"><b>Session</b>: {{ $store.state.session.sessionId }}</span>
                    <span class="sub-text"><b>Role</b>: {{ $store.state.session.userRole }}</span>
                </MediaText>
            </MediaGroup>
        </DropdownContent>
        <DropdownContent class="dropdown-content-x-lg py-3 border-bottom border-light">
            <List class="link-list">
                <ListItem>
                    <a @click="$emit('openUser')" style="cursor:pointer">
                        <Icon icon="user" />
                        <span>My Profile</span>
                    </a>
                </ListItem>
            </List>
        </DropdownContent>
        <DropdownContent class="dropdown-content-x-lg py-3">
            <List class="link-list">
                <ListItem>
                    <a href="#" @click.prevent="handleSignout">
                        <Icon icon="signout" />
                        <span>Log Out</span>
                    </a>
                </ListItem>
            </List>
        </DropdownContent>
  
    </DropdownMenu>
    
</template>

<script>

import DropdownMenu from '@/components/uielements/dropdown/DropdownMenu.vue';
import DropdownContent from '@/components/uielements/dropdown/DropdownContent.vue';
import MediaGroup from '@/components/media/MediaGroup.vue';
import Media from '@/components/media/Media.vue';
import MediaText from '@/components/media/MediaText.vue';
import Icon from '@/components/icon/Icon.vue';
import List from '@/components/list/List.vue';
import ListItem from '@/components/list/ListItem.vue';

export default {
    name: 'UserProfileDropdown',
    methods: {
        handleSignout() {
            this.$store.commit('setLogout');
            this.$router.push('/auths/auth-login');
        }
    },
    components: {
        DropdownMenu,
        DropdownContent,
        MediaGroup,
        Media,
        MediaText,
        Icon,
        List,
        ListItem
    }
}
</script>
