<template>
    <Multiselect placeholder="Address Label" v-model="value2" :canClear="false" :create-option="true" :searchable="true"
        :options="options" :disabled="loading" />
</template>
<script>
import Multiselect from '@vueform/multiselect'
export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            loading: false,
            value2: this.value,
            options: ['Shipping Address','Billing Address']
        }
    },
    watch: {
        value: function (vnew) {
            if (!this.options.includes(vnew) && vnew) {
                this.options.push(vnew);
            }
            this.value2 = vnew;
            this.$emit('change-brand', vnew);
        },
        value2: function (vnew, vold) {
            if (!vnew) {
                this.value2 = vold;
                this.$emit('change-brand', vold);
            } else {
                this.$emit('change-brand', vnew);
            }
        }
    },
    props: {
        value: {
            type: String,
            default() {
                return 'Not Informed'
            }
        },
    },
    methods: {
    },
    created() {
       

    }
}
</script>
