<template>
    <div class="dropdown-title">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'DropdownTitle'
}
</script>
