import { httpCore } from './http.js'
export default {
    add(dto) {
        return httpCore.post(`/company`, dto);
    },
    delete(id) {
        return httpCore.delete(`/company/${id}`);
    },
    get(id) {
        return httpCore.get(`/company/${id}`);
    },
    update(id, dto) {
        return httpCore.put(`/company/${id}`, dto);
    },
    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/company-filter/",requestParams);
    },

}