<template>
    <template v-if="$store.state.config.isQbOnline && qbSync2">
        <template v-if="qbSync2.status == 'PENDING_CREATE'">
                <em class="icon ni ni-circle-fill text-info blob" ></em>
        </template>

        <template v-if="qbSync2.status == 'PENDING_SYNC'">
                <em class="icon ni ni-circle-fill text-purple blob" ></em>
        </template>

        <template v-if="qbSync2.status == 'ERROR_CREATE'">
                <em class="icon ni ni-circle-fill text-danger" ></em>
        </template>

        <template v-if="qbSync2.status == 'SYNCED'">
             <em class="icon ni ni-circle-fill text-success " ></em>
        </template>

        <template v-if="qbSync2.status == 'ERROR_SYNC'">
                <em class="icon ni ni-circle-fill text-warning" ></em>
        </template>

        
    </template>
</template>
<script>
export default {
    data() {
        return {
            qbUpdated2 :this.qbUpdated,
            qbSync2: this.qbSync,
        }
    },
    watch: {
        qbSync: function (nw) {
            this.qbSync2 = nw
        },
        qbUpdated: function (nw) {
            this.qbUpdated2 = nw
        },
    },
    props: {
        qbUpdated: {
            type: Boolean,
            default: null
        },
        qbSync: {
            type: Boolean,
            default: null
        },
    },
}
</script>
<style scoped>
:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 10px;
    --popper-theme-text-size: 10px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.blob {
    border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}


@keyframes pulse {
	0% {
		transform: scale(0.80);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.80);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}



</style>