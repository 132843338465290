import { httpCore } from './http.js'
export default {



    get(id) {
        return httpCore.get(`/payroll/${id}`);
    },
    delete(id) {
        return httpCore.delete(`/payroll/${id}`);
    },
    add(dto) {
        return httpCore.post(`/payroll/`, dto);
    },


    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/payroll-filter/",requestParams);
    },
}