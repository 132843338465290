<template>
    <div class="nk-header nk-header-fixed">
        <div class="container-fluid">
            <div class="nk-header-wrap">
                <BlockHeadContent>
                    <BlockTitle tag="h3">
                        <Icon :icon="$store.state.navigation.pageIcon"></Icon>
                        {{ $store.state.navigation.pageTitle }}
                        <router-link to="/notification/unread" v-if="unread > 0" class="ms-5 alert alert-danger">
                            You have <b>{{unread}}</b> unread notifications
                        </router-link>
                    </BlockTitle>
                </BlockHeadContent>
                <div class="nk-header-logo ms-n1">
                    <div class="nk-sidebar-toggle">

                        <Button size="sm" zoom class="btn-icon sidebar-toggle d-sm-none">
                            <Icon icon="menu" />
                        </Button>
                        <Button size="md" zoom class="btn-icon sidebar-toggle d-none d-sm-inline-flex">
                            <Icon icon="menu" />
                        </Button>
                    </div><!-- .nk-sidebar-toggle -->
                    <div class="nk-navbar-toggle me-2">
                        <Button size="sm" zoom class="btn-icon navbar-toggle d-sm-none">
                            <Icon icon="menu-right" />
                        </Button>
                        <Button size="md" zoom class="btn-icon navbar-toggle d-none d-sm-inline-flex">
                            <Icon icon="menu-right" />
                        </Button>
                    </div><!-- .nk-navbar-toggle -->
                    <Logo />
                </div>

                <nav class="header-menu nk-navbar">
                    <Nav />
                   
                </nav>
                <div class="nk-header-tools">
                    
                    <QuickNav class="ms-2">
                        <button class="btn btn-sm btn-outline-info me-2" @click="handleSignout">  <em class="icon ni ni-signout me-2  " ></em> Logout</button>
                        <QuickNavItem class="dropdown" v-if="false">
                            <Button size="sm" zoom class="btn-icon d-sm-none" data-bs-toggle="dropdown"
                                data-bs-auto-close="outside">
                                <Icon icon="search" />
                            </Button>
                            <Button size="md" zoom class="btn-icon d-none d-sm-inline-flex" data-bs-toggle="dropdown"
                                data-bs-auto-close="outside">
                                <Icon icon="search" />
                            </Button>
                            <SearchDropdown />
                        </QuickNavItem>
                        <QuickNavItem v-if="false">
                            <Button size="sm" zoom class="btn-icon d-sm-none" data-bs-toggle="offcanvas"
                                data-bs-target="#notificationOffcanvas">
                                <Icon icon="bell" />
                            </Button>
                            <Button size="md" zoom class="btn-icon d-none d-sm-inline-flex" data-bs-toggle="offcanvas"
                                data-bs-target="#notificationOffcanvas">
                                <Icon icon="bell" />
                            </Button>
                        </QuickNavItem>
                        <QuickNavItem class="dropdown">
                            <QuickNavLink data-bs-toggle="dropdown">
                                <div class="d-sm-none">
                                    <Media shape="circle" size="md">
                                        <Img src="/images/avatar/a.jpg" thumbnail></Img>
                                    </Media>
                                </div>
                                <div class="d-none d-sm-block">
                                    <Media shape="circle">
                                        <img class="img-thumbnail" style="width: 4em; height: 3em;"
                                            :src='`${$store.state.config.apiUrl}/user/${$store.state.session.userId}/avatar`' />
                                    </Media>
                                </div>
                            </QuickNavLink>
                            <UserProfileDropdown @openUser="$emit('openUser')" />
                        </QuickNavItem>
                    </QuickNav>
                </div><!-- .nk-header-tools -->
            </div><!-- .nk-header-wrap -->
        </div><!-- .container-fliud -->

    <!-- sidebar notification -->
    <NotificationOffcanvas></NotificationOffcanvas>
</div>


</template>


<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-unused-components -->
<script>
// import components
import headerTabs from '@/components-smartbase/headerTabs.vue';
import {BlockTitle} from '@/components/block/Block.vue';
import Img from '@/components/img/Img.vue';
import Nav from '@/layout/default/nav/Nav.vue';
import Media from '@/components/media/Media.vue';
import Icon from '@/components/icon/Icon.vue';
import NotificationMixing from '@/mixings/NotificationMixing';
import QuickNav from '@/components/quicknav/QuickNav.vue';
import QuickNavItem from '@/components/quicknav/QuickNavItem.vue';
import QuickNavLink from '@/components/quicknav/QuickNavLink.vue';
import Logo from '@/components/logo/Logo.vue';
import Button from '@/components/uielements/button/Button.vue';
import NotificationOffcanvas from '@/components/user/NotificationOffcanvas.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import UserProfileDropdown from '@/components/user/UserProfileDropdown.vue';
export default {
    mixins:[NotificationMixing],
  name: 'AppHeader',
    data(){
    return {
        unread : 0    
    }
    },
  components: {
      Img,
    headerTabs,
    Nav,
    Media,
    Icon,
    QuickNav,
    BlockTitle,
    QuickNavItem,
    QuickNavLink,
    Logo,
    Button,
    NotificationOffcanvas,
    SearchDropdown,
    UserProfileDropdown
    },
    
    methods: {
        async loadUnread(){
            const {data} = await NotificationMixing.unreadCount();
            this.unread = data;
        },  
        handleSignout() {
            this.$store.commit('setLogout');
            this.$router.push('/auths/auth-login');
        },

        tabActive(event) {
            this.$router.replace({
                query: { ...this.$route.query, id: event }
            })
        },
  },
  mounted(){
    this.loadUnread();
    var _this = this;
    /*
    window.setInterval(function(d){
        _this.loadUnread();
    }, 8000);
    */
    let Break = { mb: 420, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1540, any: Infinity };
    let body = document.querySelector('body');
    let appRoot = document.querySelector('.nk-app-root');
    let Win = { height: window.innerHeight, width: window.innerWidth };

    let navbarVariables = {
        classes:{
            base: 'nk-navbar',
            toggle: 'navbar-toggle',
            toggleActive: 'active',
            active: 'navbar-active',
            overlay: 'navbar-overlay',
            body: 'navbar-shown',
        },
        break: {
            main: appRoot.dataset.sidebarCollapse ? eval(`Break.${appRoot.dataset.sidebarCollapse}`) : Break.lg,
        }
    };

    let Navbar = {
        show: function(toggle,target) {
            toggle.forEach(toggleItem => {
                toggleItem.classList.add(navbarVariables.classes.toggleActive);
            })
            target.classList.add(navbarVariables.classes.active);
            body.classList.add(navbarVariables.classes.body);
            let overalyTemplate = `<div class='${navbarVariables.classes.overlay}'></div>`
            target.insertAdjacentHTML('beforebegin', overalyTemplate);
        },
        hide: function(toggle,target) {
            toggle.forEach(toggleItem => {
                toggleItem.classList.remove(navbarVariables.classes.toggleActive);
            })
            target.classList.remove(navbarVariables.classes.active);
            body.classList.remove(navbarVariables.classes.body);
            let overlay = document.querySelector(`.${navbarVariables.classes.overlay}`);
            setTimeout(() => {
                overlay && overlay.remove();
            }, 300);
        },
        mobile: function(target){
            if(navbarVariables.break.main < Win.width){
                target.classList.remove('navbar-mobile');
            }else{
                setTimeout(() => {
                    target.classList.add('navbar-mobile');
                }, 500);
            }
        }
    }

    let NavbarInit = function() {
        let targetSl = document.querySelector(`.${navbarVariables.classes.base}`);
        let toggleSl = document.querySelectorAll(`.${navbarVariables.classes.toggle}`);
        toggleSl.forEach(item => {
            Navbar.mobile(targetSl);
            item.addEventListener("click", function(e){
                e.preventDefault();
                if(navbarVariables.break.main > Win.width){
                    if(!targetSl.classList.contains(navbarVariables.classes.active)){
                        Navbar.show(toggleSl,targetSl);
                    }else{
                        Navbar.hide(toggleSl,targetSl);
                    }
                }
            });

            window.addEventListener("resize", function(){
                if(navbarVariables.break.main < Win.width){
                    Navbar.hide(toggleSl,targetSl);
                }
                Navbar.mobile(targetSl);
            });

            document.addEventListener("mouseup", function(e){
                if(e.target.closest(`.${navbarVariables.classes.base}`) === null){
                    Navbar.hide(toggleSl,targetSl);
                }
            });
            
        })
    }

    NavbarInit()


  },
  
}
</script>
