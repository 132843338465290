<template>
    <div class="modal-footer">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ModalFooter'
    }
</script>
