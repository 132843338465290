<template>
    <div class="tab-content">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'TabContent'
    }
</script>
