<template>
    <div class="media-text" style="    width: 100%;"> 
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'MediaText'
}
</script>
