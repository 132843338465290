<template>
    <label class="form-label" :class="isSize + size">
        <slot></slot>
    </label>
</template>

<script>
    export default {
        name: 'FormLabel',
        size: {
          type: String
        },
      computed: {
        isSize(){
          if(this.size){
            return 'form-label-'
          }else{
            return null
          }
        }
      }
    }
</script>
