<template>
    <tbody>
        <slot></slot>
    </tbody>
</template>

<script>
    export default {
        name: 'TableBody'
    }
</script>
