<template>
    <Dropdown v-model="value2" :options="options" placeholder="Select Status" class="w-full w100" style="width:100%">
        <template #option="slotProps">
            <badgeEmployStatus :status="slotProps.option" />
        </template>
        <template #value="slotProps">
            <badgeEmployStatus :status="slotProps.value" v-if="slotProps.value" />
        </template>
    </Dropdown>
</template>
<script>
import badgeEmployStatus from '@/components-smartbase/employ/badgeEmployStatus.vue';
import Dropdown from 'primevue/dropdown';
import EmployMixing from '@/mixings/EmployMixing';

export default {
    components: {
        Dropdown,
        badgeEmployStatus
    },
    data() {
        return {
            value2: this.value,
            options: []
        }
    },
    watch: {
        value2: function (newv) {
            this.$emit('change-brand', newv);
        },
        value: function () {
            //alert('teste2');
            //this.value2 = vnew;
        },
    },
    props: {
        value: {
            type: String,
            default() {
                return 'NEW'
            }
        },
    },
    methods: {
        async loadDb() {
            const data = await EmployMixing.status();
            var _this = this;

            for (const status of data) {
                _this.options.push(status);
            }
        }
    },
    mounted() {
        this.loadDb();
    }
}
</script>
