<template>
    <div class="modal"  data-backdrop="static" data-keyboard="false"   :class="animation && animation" data-bs-keyboard="false" data-bs-backdrop="static">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ModalContainer',
        props: {
            animation: String,
            backdrop: String,
            keyboard: String
        }
    }
</script>
