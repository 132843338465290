import { httpCore } from './http.js'
export default {
    add(dto) {
        return httpCore.post(`/job`, dto);
    },
    delete(id) {
        return httpCore.delete(`/job/${id}`);
    },
    get(id) {
        return httpCore.get(`/job/${id}`);
    },
    update(id, dto) {
        return httpCore.put(`/job/${id}`, dto);
    },
    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/job-filter/",requestParams);
    },

}