<template>
    <div class="nk-footer">
        <div class="container-fluid">
            <div class="nk-footer-wrap">
                <div class="nk-footer-copyright">
                    &copy; 2024 SmartTrack. Software by
                    <Link href="https://billrighetto.com/" target="_blank" class="text-reset">Bill & Righetto</Link>
                    licensed to: <b>{{ $store.state.config.licensedFor }} - {{ $store.state.config.version }}</b>
                </div>
                <div class="nk-footer-links">
                    <Nav class="nav-sm">
                        <NavItem>
                            <Link class="nav-link" as="RouterLink" to="/">Support</Link>
                        </NavItem>
                    </Nav>
                </div>
            </div>
        </div>
    </div><!-- .nk-footer -->
</template>

<script>
import Link from '@/components/link/Link.vue';
import Nav from '@/components/uielements/nav/Nav.vue';
import NavItem from '@/components/uielements/nav/NavItem.vue';
//import NavLink from '@/components/uielements/nav/NavLink.vue';

export default {
    name: 'AppFooter',
    components: {
        Link,
        Nav,
        NavItem,
        //NavLink
    }
}
</script>
