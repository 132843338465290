import CompanyMixing from './CompanyMixing.js';
import { httpCore } from './http.js'
export default {
    status(){
        return ['NEW','ACTIVE', 'FIRED', 'ABSENT', 'INACTIVE']
    },
    defaultNoPhoto(){
        return `/images/avatar/avatar-placeholder.jpg`;
    },
    async companies(){
        const { data } = await CompanyMixing.filter(1000000, 0, '', 'name', 'desc', {});
        return data.data;
    },
    companiesColor(){
        return {
            "Tribeca NJ" : 'table-danger bg-danger bg-opacity-25',
            "Tribeca NY" : 'table-warning bg-warning bg-opacity-25',
            "All Vision General Construction" : 'table-success bg-success bg-opacity-25',
        }
    },
    photoUrl(photoId){
        if(this.id2 == null || this.id2 == '')
            return this.defaultNoPhoto();
        return `http://localhost:3000/file/download/${photoId}`
    },
    roles(){
        return httpCore.get(`/employ-roles/`);
    },
    get(id) {
        return httpCore.get(`/employ/${id}`);
    },
    getWorkedHour(dto) {
        return httpCore.post(`/employ-workedHour/`, dto);
    },
    delete(id) {
        return httpCore.delete(`/employ/${id}`);
    },
    add(dto) {
        return httpCore.post(`/employ/`, dto);
    },
    update(id, dto) {
        return httpCore.put(`/employ/${id}`, dto);
    },

    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/employ-filter/",requestParams);
    },
}