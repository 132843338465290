<template>
    <ul class="nk-schedule">
        <slot></slot>
    </ul>
</template>

<script>
export default {
    name: 'ScheduleContainer'
}
</script>
