import {  httpCore } from './http.js'

export default {
    async refreshToken(token) {
        return await httpCore.post(`/session/refresh/?refreshToken=${token}`);
    },

    all() {
        return httpCore.get(`/users/`);
    },
    get(userId) {
        return httpCore.get(`/user/${userId}`);
    },
    add(dto) {
        return httpCore.post(`/user/`,dto);
    },
    avatar(userId) {
        return httpCore.post(`/user/${userId}/avatar`);
    },
    update(id, dto) {
        return httpCore.put(`/user/${id}`, dto);
    },
    login(login, password) {
        return httpCore.post(`/login/`,{ login, password});
    },
    filter(pageSize, currentPage, searchText, ordem, ordem_por, advanced) {
        var requestParams = {
            pageSize : pageSize,
            actualPage : currentPage,
            query : searchText,
            order : ordem,
            order_by : ordem_por,
            advanced
        };
        return httpCore.post("/user-filter/",requestParams);
    },
}