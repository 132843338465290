<template>
    <div class="nk-wrap">
        <slot></slot>
    </div>
</template>

<script>

export default {
  name: 'AppWrap'
}
</script>
