<template>
    <VueAwesomeSideBar :keepChildrenOpen="true" :dark="true" menuType="fully" width="15em" class="testMenue"
        v-model:miniMenu="miniMenu2" :collapsed="false" :menu="menuItem" vueRouterEnabel>

        <template #header>
            <div class="logo-wrap text-center" style="margin-bottom: 1em; margin-left: 3em;     margin-top: 1em;" v-if="miniMenu == false">
                <img src="/logo1.png" style="width: 8em;height: 6em;">

            </div>

            <div class="logo-wrap" style="margin-top: 2em;" v-if="miniMenu">
                <img :src="$store.state.config.logoWhite" style="width: 8em; height: 2em;">
            </div>
            <div class="logo-wrap text-center" style="margin-bottom: 3em; margin-left: 3em;">
                <img :src="$store.state.config.logoUrl" style="width: 8em; height: 3adicem;">
            </div>

        </template>
        <template #footer>
            <div class="ms-2 mb-2" style="    line-height: 1.3em; color:white">
                <small>
                    Licensed For: <br><b>-</b><br><br>
                    Logged as: <b>{{ $store.state.session.userName }}</b><br>
                    Version: <b>{{ $store.state.config.version }}</b>
                    <template v-if="$store.state.config.isProd == false"><br><br>
                        <div class="">DEMO version</div>
                    </template>
                </small>
            </div>
        </template>
    </VueAwesomeSideBar>
    {{ miniMenu2 }}

    <img v-if="!miniMenu2" src="/favicon.png"
        style="position: absolute; width: 2.5em; z-index: 1111; right: 0; bottom: 0; margin-bottom: 0.5em;">
</template>

<script>

export default {
    name: 'MenuContainer',
    data() {
        return {
            miniMenu2: this.miniMenu,
        }
    },
    watch: {
        miniMenu2: function (nev) {
            this.$emit('change', nev)
        }
    },
    computed: {
        menuItem() {
            var menu = [];
            /*
                        menu.push({
                            icon: { class: 'icon ni ni-line-chart-up mb-1' },
                            href: '/dashboard/',
                            name: 'Dashboard',
                        });
              */



            if (this.$store.state.session.userRole == 'ADMIN') {
                menu.push({
                    icon: { class: 'icon ni ni-users mb-1' },
                    href: '/users/',
                    name: 'Users',
                });
                menu.push({
                    icon: { class: 'icon ni ni-coins mb-1' },
                    href: '/bonus/',
                    name: 'Bonus',
                });
                menu.push({
                    icon: { class: 'icon ni ni-exchange-v mb-1' },
                    href: '/payrollAdjustment/',
                    name: 'Payroll Adjustment',
                });
                menu.push({
                    icon: { class: 'icon ni ni-share-alt mb-1' },
                    href: '/employees/',
                    name: 'Employees',
                });
            }

            if (this.$store.state.session.userRole == 'SECRETARY') {
                menu.push({
                    icon: { class: 'icon ni ni-exchange-v mb-1' },
                    href: '/payrollAdjustment/',
                    name: 'Payroll Adjustment',
                });
                menu.push({
                    icon: { class: 'icon ni ni-share-alt mb-1' },
                    href: '/employees/',
                    name: 'Employees',
                });
            }

            if (this.$store.state.session.userRole == 'ADMIN' ||  this.$store.state.session.userRole == 'OFFICE') {
                menu.push({
                    icon: { class: 'icon ni ni-home mb-1' },
                    href: '/jobs/',
                    name: 'Jobs',
                });
                menu.push({
                    icon: { class: 'icon ni ni-bell mb-1' },
                    href: '/notification/unread',
                    name: 'Notifications',
                });
                menu.push({
                    icon: { class: 'icon ni ni-live mb-1' },
                    href: '/roles/',
                    name: 'Roles',
                });
                menu.push({
                    icon: { class: 'icon ni ni-building mb-1' },
                    href: '/companies/',
                    name: 'Companies',
                });
                menu.push({
                    icon: { class: 'icon ni ni-coins mb-1' },
                    href: '/timeSheets/',
                    name: 'Time Sheets',
                });
                menu.push({
                    icon: { class: 'icon ni ni-share-alt mb-1' },
                    href: '/employees/',
                    name: 'Employees',
                });
            }
            return menu;
        }
    },
    props: {
        miniMenu: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style>
.vas-fully,
.label {
    margin-top: -3px;
    margin-bottom: -3px;

    font-size: 14px !important;
}

.vas-fully .activeClass {
    padding-left: 0px;
    height: 2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.vas-fully.labelHoverClass {
    padding-left: 0px;
    height: 3em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.items-container {
    font-weight: 100;
}

.vas-menu.dark-theme .label.activeClass:before {
    background: rgb(191, 109, 1) !important;
}

.left {
    margin-left: 1em !important;
}
</style>
