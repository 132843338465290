<template>
    <ModalContainer animation="fade" :id="inputId" aria-labelledby="exampleModalLgLabel" tabindex="-1">
        <ModalDialog size="xl">
            <ModalContent>
                <ModalHeader>
                    <h5 class="modal-title" id="exampleModalLgLabel">
                        <em class="icon ni ni-coins  "></em> Create PayRoll
                    </h5>
                    <a dismiss="modal" aria-label="Close"></a>
                </ModalHeader>
                <ModalBody>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">

                                <div class="col-md-2 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Last Week?</label>
                                        <div class="form-control-wrap">
                                            <input :value="true" type="checkbox" v-model="lastWeek"
                                                @click="setLastWeek">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2" v-if="!lastWeek">
                                    <div class="form-group">
                                        <label class="form-label">Begin</label>
                                        <div class="form-control-wrap">
                                            <input class="form-control" type="date" v-model="dto_payroll.begin">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2" v-if="!lastWeek">
                                    <div class="form-group">
                                        <label class="form-label">End</label>
                                        <div class="form-control-wrap">
                                            <input class="form-control" type="date" v-model="dto_payroll.end">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Calculate</label>
                                        <div class="form-control-wrap">
                                            <button type="button" class="btn btn-success" @click="getHours">
                                                <em class="icon ni ni-calc"></em> Calculate
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-border  dataTable-table mt-1">
                                    <thead>
                                        <tr>
                                            <th>Employee</th>
                                            <th class="col-md-1">WT</th>
                                            <th class="col-md-1">ET</th>
                                            <th class="col-md-1">Total hour</th>
                                            <th class="col-md-1">Pay in Check</th>
                                            <th class="col-md-1">Pay in Cash</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="emp in employList" :key="emp._id">
                                            <td class="small">{{ emp.name }}</td>
                                            <td class="small">
                                                <span v-if="emp.regularHours > 0" > {{ UtilMixing.numberToTime(emp.regularHours) }}</span>
                                                <span class="text-muted" v-else>-</span>
                                            </td>
                                            <td class="small">
                                                <span v-if="emp.extraHours > 0" class="text-info"> {{ UtilMixing.numberToTime(emp.extraHours) }}</span>
                                                <span class="text-muted" v-else>-</span>
                                            </td>
                                            <td class="small">
                                                <span v-if="emp.hours > 0"> {{ UtilMixing.numberToTime(emp.hours) }}</span>
                                                <span class="text-muted" v-else>-</span>
                                            </td>
                                            <td>
                                                <input type="time" class="form-control form-control">
                                            </td>
                                            <td class="col-md-1 text-left">
                                                <input v-if="emp.hasSalaryCheck" type="time" class="form-control">
                                                <span v-else class="text-muted">N/A</span>
                                            </td>
                                            <td class="small"></td>
                                        </tr>
                                    </tbody>
                                </Table>

                            </div>


                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div type="button" class="btn btn-outline-dark" :disabled="isDisabled" dismiss="modal"
                        @click="modal.hide()">
                        <Icon icon="cross"></Icon> Cancel
                    </div>
                    <button type="button" class="btn btn-success" :disabled="isDisabled" @click="create">
                        <em class="icon ni ni-save"></em> Save
                    </button>
                </ModalFooter>
            </ModalContent>
        </ModalDialog>
    </ModalContainer>
</template>
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-unused-components -->
<script>
import moment from 'moment-timezone';
import ImageUpload from '@/components/forms/form-upload/ImageUpload.vue';
import selectEmployRole from '@/components-smartbase/employ/selectEmployRole.vue'
import selectEmployStatus from '@/components-smartbase/employ/selectEmployStatus.vue'
import Messages from '@/mixings/Messages';
import ColFormLabel from '@/components/forms/form-label/ColFormLabel.vue';
import Icon from '@/components/icon/Icon.vue';
import PrinterMixing from "../../mixings/PrinterMixing.js";
import FormInput from '@/components/forms/input/FormInput.vue';
import ModalFooter from '@/components/uielements/modal/ModalFooter.vue';
import ModalContainer from '@/components/uielements/modal/ModalContainer.vue';
import ModalDialog from '@/components/uielements/modal/ModalDialog.vue';
import ModalContent from '@/components/uielements/modal/ModalContent.vue';
import ButtonClose from '@/components/uielements/button-close/ButtonClose.vue';
import ModalBody from '@/components/uielements/modal/ModalBody.vue';
import ModalHeader from '@/components/uielements/modal/ModalHeader.vue';
import Button from '@/components/uielements/button/Button.vue';
import { Modal } from 'bootstrap';

import UtilMixing from "@/mixings/UtilMixing.js";
import PayRollMixing from "@/mixings/PayRollMixing.js";

import FormLabel from '@/components/forms/form-label/FormLabel.vue';
import { helpers, email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import selectRelationshipContactLabel from '@/components-smartbase/selectRelationshipContactLabel.vue';
import TimeSheetMixing from '@/mixings/TimeSheetMixing.js';
import EmployMixing from '@/mixings/EmployMixing.js';
export default {
    setup() {
        return { v$: useVuelidate() }
    },
    mixins: [UtilMixing, TimeSheetMixing, EmployMixing, PayRollMixing],
    props: {
        inputId: {
            type: String,
            default() {
                return UtilMixing.uuid();
            }
        },
    },
    components: {
        ImageUpload,
        selectEmployRole,
        selectEmployStatus,
        ModalBody,
        selectRelationshipContactLabel,
        Icon,
        ColFormLabel,
        FormLabel,
        FormInput,
        Button,
        ModalFooter,
        ButtonClose,
        ModalContainer,
        ModalContent,
        ModalDialog,
        ModalHeader
    },
    data() {
        return {
            employList: [],
            lastWeek: true,
            dto_timeSheet: {
                date: moment(new Date()).format("yyyy-MM-DD"),
                startTime: '09:00',
                perHour: 0,
                notes: '',
                adjustment: 0,
                endTime: '10:00'
            },
            dto_payroll: {
                begin: undefined,
                end: undefined,
                paidAt: moment(new Date()).format("yyyy-MM-DD"),
                employId: null,
                amount: 0,
                notes: ""
            },
            modal: {},
            employ: null,
        }
    },
    computed: {
        UtilMixing() {
            return UtilMixing
        },

        isDisabled() {
            return this.dto_payroll.amount == 0 ? true : false;
        },
        newBalance() {
            return this.employ.payroll.pending - this.dto_payroll.amount;
        },
    },
    methods: {
        async getHours() {
            const { data } = await EmployMixing.getWorkedHour({ begin: this.dto_payroll.begin, end: this.dto_payroll.end });
            this.employList = data;
        },
        setLastWeek() {
            let today = moment();

            // Subtrai 1 semana da data atual
            let lastweek = today.subtract(1, 'weeks');

            // Define o dia da semana como sexta-feira (5 é sexta-feira em Moment.js)

            this.dto_payroll.begin = moment(lastweek.day(1)).format("yyyy-MM-DD");
            this.dto_payroll.end = moment(lastweek.day(5)).format("yyyy-MM-DD");
        },
        async create() {
            try {
                var dialog = Messages.freezeLoading('Adding time payroll...');
                const { data } = await PayRollMixing.add(this.dto_payroll);
                Messages.toast_success(`New PayRoll added. Code: ${data.number}`);
                this.$emit('payroll-created');
                this.modal.hide();
            } catch (e) {
                Messages.warning('Not able to create payroll', e);
            } finally {
                Messages.closeFreeze(dialog);
            }
        },
        async showModal() {
            this.modal = new Modal('#' + this.inputId);
            this.dto_payroll = {
                amount: 0,
                notes: ""
            };
            this.setLastWeek();
            this.modal.show();
        },
    }
}
</script>
<style scoped>
.form-label {
    margin-bottom: 0em;
    margin-top: 1em;
}
</style>