<template>
    <div class="nk-main" style="    overflow: hidden;">
        <slot></slot>
    </div>
</template>

<script>

export default {
  name: 'AppMain'
}
</script>
