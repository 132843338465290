<template>
    <div class="dropdown-content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'DropdownContent'
}
</script>
